<template>
  <section class="basic-textarea">
    <div class="row">
      <div class="col-12">
        <b-overlay :show="!tableReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <div class="card">
            <div class="card-body">
              <table id="UserList" class="dataTable dt-responsive table w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th class="text-center">
                      <feather-icon icon="CameraIcon" />
                    </th>
                    <th>{{ $t('user.name') }}</th>
                    <th>{{ $t('user.surname') }}</th>
                    <th>{{ $t('Mail') }}</th>
                    <th>{{ $t('Phone') }}</th>
                    <th class="text-center">{{ $t('State') }}</th>
                    <th class="text-center">{{ $t('UserType') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData" :key="item.user_id">
                    <td>{{ item.user_id }}</td>
                    <td class="text-center">
                      <b-avatar size="40" :src="getImage(item)" />
                    </td>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>{{ item.surname }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ phoneFormat(item.phone) }}</td>
                    <td class="text-center">
                      <b-badge :variant="item.is_passive ? 'light-danger' : 'light-success'">
                        {{ item.is_passive ? $t('Passive') : $t('Active') }}
                      </b-badge>
                    </td>
                    <td class="text-center">
                      <b-badge :variant="item.sys_admin ? 'light-success' : 'light-warning'">
                        {{ item.sys_admin ? $t('Admin') : $t('User') }}
                      </b-badge>
                    </td>
                    <td class="text-center">
                      <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                          <template v-slot:button-content>
                            <i class="far fa-ellipsis-v processes-icon"></i>
                          </template>
                          <b-link
                            v-if="$can('manage', '/User/Edit')"
                            :to="{
                              name: '/User/Detail',
                              params: { id: item.user_id }
                            }"
                            target="_blank"
                            class="dropdown-item"
                          >
                            <i class="fal fa-info-circle font-small-4 mr-50"></i>
                            {{ $t('Edit') }}
                          </b-link>
                        </b-dropdown>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import { BDropdown, BDropdownItem, BLink, BBadge, BOverlay, BAvatar } from 'bootstrap-vue'
import '@/assets/js/functions/datatables.settings'
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { GET_ALL_USERS } from '@/store/services/user-service'

export default {
  name: 'user_list',
  components: {
    BDropdown,
    BDropdownItem,
    BLink,
    BBadge,
    BOverlay,
    BAvatar
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: false
    }
  },
  computed: {
    ...mapGetters(['getLocale'])
  },
  created() {
    this.$store.dispatch(GET_ALL_USERS).then(response => {
      if (response.status) {
        this.tableData = response.data
        this.$nextTick(() => {
          this.createTable()
        })
      }
    })
  },
  methods: {
    createTable() {
      this.tableReady = false
      $('#UserList').DataTable({
        columnDefs: [
          { type: 'num', targets: [0, 3, 5] },
          { orderable: false, targets: [6] }
        ],
        buttons: [
          {
            extend: 'colvis',
            text: this.$t('dataTable.show_hide')
          },
          {
            extend: 'pdf'
          },
          {
            extend: 'excel',
            title: this.$t('user_list.file_name'),
            filename: this.$t('user_list.file_name')
          }
        ],
        language: {
          url: this.getLocale == 'tr-TR' ? '//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json' : '//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json'
        }
      })
      this.tableReady = true
    },
    getImage(user) {
      if (user.image_id) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${user.image_id}`
      }
      return undefined
    },
    phoneFormat(phone) {
      return `+90 ${phone.slice(0, 3)} ${phone.slice(3, 6)} 
      ${phone.slice(6, 8)} ${phone.slice(8, 10)}`
    }
  },
  watch: {
    getLocale() {
      Promise.all([this.table.destroy()]).then(this.createTable)
    }
  }
}
</script>
